import React, { useEffect, useState } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import MonthUtil from '../../util/MonthUtil';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TotalsContainer from '../../container/payments/TotalsContainer';
import APIGContainer from '../../container/auth/APIGContainer';
import { Message } from 'primereact/message';

const TotalsComponent = ({isTotalsDialogVisible, setIsTotalsDialogVisible, paymentsPageForMonth}) => {

    const [forMonth, setForMonth] = useState(paymentsPageForMonth);
    const [loading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [totals, setTotals] = useState(null);

    const onSuccessfulFetch = (response: any) => {
        setIsLoading(false);
        try {
            if (response?.bookings?.length > 0) {
                setTotals(TotalsContainer.getMontlyTotals(response.bookings));
                setFetchError(null);
            } else {
                setTotals([]);
                setFetchError(null);
            }
        } catch (error) {
            onFetchFailure("Error while fetching Bookings. Please retry after sometime!");
        }
    };

    const onFetchFailure = (error: string) => {
        setIsLoading(false);
        setFetchError(error);
    };

    useEffect(() => {
        if(!forMonth) {
            return;
        }
        setIsLoading(true);
        APIGContainer.getBookings(forMonth, (response: any) => {
            onSuccessfulFetch(response)
        }, (error: string) => {
            onFetchFailure(error);
        });
    }, [forMonth]);

    const resetState = () => {
        setTotals([]);
        setForMonth(null);
        setIsTotalsDialogVisible(!isTotalsDialogVisible);
    }

    return (
        <div className='card'>
            <Dialog header="Payment Totals" visible={isTotalsDialogVisible} onHide={() => resetState()}  style={{ width: '50vw' }}>
                {fetchError ? <Message severity="error" text={fetchError} /> : null}
                <Dropdown value={forMonth} options={MonthUtil.constructMonths()} 
                onChange={(e) => setForMonth(e.value)} optionLabel="name" optionValue="name" 
                placeholder="Select Month" style={{textALign: "center"}} />

                <DataTable value={totals} responsiveLayout="scroll" loading={loading} emptyMessage="No Data">
                    <Column field="username" header="Username"></Column>
                    <Column field="totalOwed" header="Total Owed (Pending)"></Column>
                </DataTable>
            </Dialog>
        </div>
    )
}

export default withAuthenticator(TotalsComponent, {hideSignUp: true});