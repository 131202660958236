import React, { useEffect, useState } from "react";
import NavComponent  from "../nav/NavComponent";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterService } from 'primereact/api';
import MonthUtil from "../../util/MonthUtil";
import { Dropdown } from "primereact/dropdown";
import CommonUtil from "../../util/CommonUtil";
import ListingComponent from "./ListingComponent";
import APIGContainer from "../../container/auth/APIGContainer";

const BookingsComponent = (props: any) => {

    var dt = null;
    
    FilterService.register("custom_capturedAt", CommonUtil.dateFilter);
    FilterService.register("custom_eventTime", CommonUtil.eventTimeFilter);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'orderNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'emailId': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'eventName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'venue': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sectionRow': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'price': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'eventTime': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'deliveryMethod': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'source': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'capturedAt': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'ccNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'quantity': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'ppq': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'username': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'soldQuantity': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'profit': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'cancelled': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilter, setGlobalFilter] = useState('');
    const [listBooking, setListBooking] = useState(null);
    const [filteredBookings, setFilteredBookings] = useState(null);

    const toastTL = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if(dt) {
                const processedData = dt.processedData();
                setFilteredBookings(processedData);
            }
        });
    }, [filters, globalFilter]);

    const actionBodyTemplate = (rowData) => {
        if(!rowData.soldQuantity || rowData.soldQuantity === "Not Listed" || rowData.soldQuantity === "") {
            return (
                <div>
                    <Button label="List" onClick={() => setListBooking(rowData)} />
                    <Button label="Reset" style={{marginTop: "1px", marginLeft: "1px"}} onClick={() => resetSoldQuantity(rowData)} />
                </div>
            );
        }
        return;
    }

    const resetSoldQuantity = (booking: any) => {
        //https://discord.com/channels/873668199596568576/897716514743005224/1175127648196903003
        booking.soldQuantity = "Done";
        APIGContainer.updateBooking(booking, () => {
            toastTL.current.show({severity:'success', summary: 'Success', detail:'Successfully reset the booking', life: 3000});
        });
    }

    const cancelledTemplate = (rowData: any) => {
        const paid = rowData.cancelled === "true" ? true : false;
        return <input type='checkbox' defaultChecked={paid} disabled={true} />;
    }

    const ppqTemplate = (rowData: any) => {
        const data = rowData.ppq;
        if(!data) return "-";
        var amount = data.match(/[$€£₹¥][0-9]+([,.][0-9]+)?/);
        return amount ? amount[0] : data;
    }

    const matchModes = [
        {label: 'Contains', value: FilterMatchMode.CONTAINS},
        {label: 'Starts With', value: FilterMatchMode.STARTS_WITH},
    ];

    const columns = [
        { field: "orderNumber", header: "Order#", sortable: false, minColumnWidth: "100px" },
        { field: "emailId", header: "Email", sortable: false, minColumnWidth: "120px" },
        { field: "eventName", header: "Event", sortable: true, minColumnWidth: "150px" },
        { field: "venue", header: "Venue", sortable: true, minColumnWidth: "150px" },
        { field: "sectionRow", header: "Seat", sortable: false, minColumnWidth: "120px" },
        { field: "eventId", header: "EventId", minColumnWidth: "80px" },
        { field: "price", header: "Price", sortable: true, minColumnWidth: "80px" },
        { field: "eventTime", header: "Event Time", sortable: true, minColumnWidth: "100px" },
        { field: "deliveryMethod", header: "Delivery", sortable: false, minColumnWidth: "100px" },
        { field: "source", header: "Source", sortable: false, minColumnWidth: "80px" },
        { field: "capturedAt", header: "Captured Time", sortable: true, minColumnWidth: "100px" },
        { field: "ccNumber", header: "CC", minColumnWidth: "100px" },
        { field: "quantity", header: "Quantity", minColumnWidth: "60px" },
        { field: "ppq", header: "PPQ", minColumnWidth: "100px" },
        { field: "username", header: "Name", minColumnWidth: "80px" },
        { field: "soldQuantity", header: "Sold", minColumnWidth: "100px" },
        { field: "profit", header: "Profit", minColumnWidth: "80px" },
        { field: "cancelled", header: "Cancelled", minColumnWidth: "80px" },
        { header: "Actions"}
    ]
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }
    const columnComponents = selectedColumns.map((col, i) => {
        if(col.field === "capturedAt") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} body={(rowData: any) => CommonUtil.dateTemplate(rowData.capturedAt)} style={{ minWidth: col.minColumnWidth }} />;
        } else if(col.field === "source") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} />;
        } else if(col.field === "emailId") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} bodyStyle={{wordBreak: "break-all"}} style={{ minWidth: col.minColumnWidth }} />;
        } else if(col.header === "Actions") {
            return <Column showFilterMenu={false} showClearButton={false} key={i} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} body={actionBodyTemplate} />;
        } else if(col.field === "cancelled") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} body={cancelledTemplate} />;
        } else if(col.field === "ppq") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} body={ppqTemplate} />;
        } else if(col.field === "quantity") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} body={CommonUtil.getQuantity} />;
        } else if(col.field === "ccNumber") {
            return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} body={CommonUtil.ccTemplate} />;
        } else if(col.field === "soldQuantity") {
            // @ts-ignore
            return <Column filter filterMatchMode="custom" showFilterMenu={true} showFilterMenuOptions={true} filterMatchModeOptions={matchModes} showClearButton={false}  key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} />;
        }
        return <Column filter showFilterMenu={false} showClearButton={false} key={col.field} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} />;
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilter(value);
    }

    const onCustomSaveState = (state) => {
        state.columnVisibility = selectedColumns;
        state.globalFilter = globalFilter;
        localStorage.setItem('dt-state-bookings-page', JSON.stringify(state));
    }

    const onCustomRestoreState = () => {
        const state = JSON.parse(localStorage.getItem('dt-state-bookings-page'));
        if(state && state.columnVisibility) {
            setSelectedColumns(state.columnVisibility);
        }
        if(state && state.globalFilter) {
            setGlobalFilter(state.globalFilter);
        }
        if(state && state.filters) {
            setTimeout(() => {
                setFilters(state.filters);
            }, 2000);
        }
            
        return state;
    }

    const resetTableState = () => {
        dt.reset();
        setSelectedColumns(columns);
        localStorage.removeItem('dt-state-bookings-page');
        props.onRefresh();
    }

    const exportCSV = () => {
        dt.exportCSV();
    }

    const constructedAggregatedQuantity = () => {
        let quantitySum = 0;
        
        const filteredData = filteredBookings === null ? props.bookings : filteredBookings;
        for(let i=0; i<filteredData.length; i++) {
            const quantity = CommonUtil.getQuantity(filteredData[i]);
            if(!quantity || quantity === "-" || quantity === "") continue;
            const quantityNum = parseInt(quantity);
            quantitySum = quantitySum + quantityNum;
        }
        return quantitySum;
    }

    const displayAggregatedData = () => {
        return "Total " + constructedAggregatedQuantity() + " quantity";
    }

    const paginatorRight = <Button type="button" icon="pi pi-stop-circle" className="p-button-text" tooltipOptions={{ position: "left"}} tooltip="Reset Preferences" onClick={() => {resetTableState()}}/>;

    const paginatorLeft = <MultiSelect value={selectedColumns} options={columns} selectedItemsLabel={"Showing {} of " + columns.length + " columns"} optionLabel="header" onChange={onColumnToggle} style={{width:'17em'}} maxSelectedLabels={3} />

    const header = (
        <span className="flex justify-content-between flex-wrap">
            <span>
                <NavComponent signOut={undefined} user={undefined} props={undefined} />
                <Button style={{ marginLeft: "5px"}} label="Bookings" className="p-button-text p-button-plain" disabled={false} />
            </span>

            <span>
                <Dropdown value={props.forMonth} options={MonthUtil.constructMonths()} onChange={(e) => {setFilteredBookings(null);props.setMonthCallback(e.value)}} optionLabel="name" optionValue="name" placeholder="Select Month" />
            </span>
            <span className="p-input-icon-left">
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} style={{marginRight: "5px"}} />
                <Button icon="pi pi-refresh" style={{marginRight: "5px"}} onClick={() => props.onRefresh()} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </span>
        </span>
    );

    return (
        <div className="datatable-templating-demo">
            <Toast ref={toastTL} position="top-left" />
            {listBooking ? <ListingComponent booking={listBooking} setListBooking={setListBooking} toastTL={toastTL} tokens={props.tokens}/> : null}
            <div className="card" style={{ height: '100vh' }}>
                <DataTable
                ref={(el) => dt = el}
                value={props.bookings} 
                header={header} 
                loading={props.loading} 
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                rows={500} 
                rowsPerPageOptions={[200,500,1000]}
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                filterDisplay="row"
                filters={filters}
                scrollable scrollHeight="flex"
                reorderableColumns
                autoLayout={true}
                stateStorage="custom" 
                customSaveState={onCustomSaveState} 
                customRestoreState={onCustomRestoreState}
                emptyMessage="No Bookings found."
                dataKey="id"
                size="small"
                responsiveLayout={"scroll"}
                globalFilter={globalFilter}
                footer={displayAggregatedData}
                onValueChange={filteredData => setFilteredBookings(filteredData)}>
                    {columnComponents}
                </DataTable>
            </div>
        </div>
    );
};

export default BookingsComponent;