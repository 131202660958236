import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from "./container/Root";
import ServiceWorkerUtil from './util/ServiceWorkerUtil';
import { API, Auth } from 'aws-amplify';
import authConfig from './aws/AuthConfig';
import apiConfig from './aws/ApiConfig';

Auth.configure(authConfig);
API.configure(apiConfig);

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

ServiceWorkerUtil.register();