import React, { useEffect, useRef, useState } from "react";
import { Message } from 'primereact/message';
import APIGContainer from "../auth/APIGContainer";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { FilterMatchMode, FilterService } from "primereact/api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ITransaction } from "../../interface/ITransaction";
import { Button } from "primereact/button";
import NavComponent from "../../component/nav/NavComponent";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

const TransactionsContainer = () => {
    var transactionsDT: any = null;
    const toastTL = useRef(null);

    const dateFilter = (value: any, filter: any) => {
        if(!filter) return true;
        return new Date(parseInt(value)).toLocaleString().includes(filter);
    };

    FilterService.register("custom_emptyBookingId", (value) => {
        if(!value || value === "") return true;
        return false;
    });
    FilterService.register("custom_notEmptyBookingId", (value) => {
        if(!value || value === "") return false;
        return true;
    });
    FilterService.register("custom_modifiedDate", dateFilter);
    FilterService.register("custom_postedDate", dateFilter);
    FilterService.register("custom_authDate", dateFilter);

    const [transactions, setTransactions] = useState<ITransaction[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [transactionsErrorMessage, setTransactionsErrorMessage] = useState<string | undefined>(undefined);
    const [globalFilter, setGlobalFilter] = useState('');
    
    const [transactionsFilters, setTransactionsFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'cardNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'amount': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'authDate': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'cardId': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'description': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'modifiedDate': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'postedDate': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'status': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'bookingId': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'creator': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    useEffect(() => {
        setLoading(true);
        APIGContainer.getTransactions((res) => {
            setLoading(false);
            if(res.response === "Success") {
                setTransactions(res.transactions);
            } else {
                setTransactionsErrorMessage("Failure while fetching transactions");
            }
        })
    }, []);

    const epochTemplate = (date: number) => {
        return date && new Date(date).toLocaleString();
    }

    const exportTransactionsAsCSV = () => {
        transactionsDT.exportCSV();
    }

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...transactionsFilters };
        _filters['global'].value = value;

        setTransactionsFilters(_filters);
        setGlobalFilter(value);    
    }

    const markTransaction = (transaction: ITransaction) => {
        transaction.bookingId = "Listed";
        APIGContainer.updateTransaction(transaction, () => {
            toastTL.current.show({severity:'success', summary: 'Transaction marked as Listed', life: 2000});
            const results = transactions.map((x: ITransaction) => [transaction].find(({ id }) => id === x.id) || x);
            setTransactions(results);
        }, () => {
            toastTL.current.show({severity:'error', summary: 'Something went wrong. Please retry.', life: 2000})
        })
    }

    const actionBodyTemplate = (transaction: ITransaction) => {
        if(!transaction.bookingId || transaction.bookingId === "") {
            return (
                <div>
                    <Button label="List" onClick={() => markTransaction(transaction)} />
                </div>
            );
        }
    }

    // @ts-ignore
    const matchModes: ColumnFilterMatchModeOptions = [
        {label: 'Empty', value: "custom_emptyBookingId"},
        {label: 'Not Empty', value: "custom_notEmptyBookingId"},
        {label: 'Contains', value: FilterMatchMode.CONTAINS},
    ];  

    const transactionsHeader = (
        <span className="flex justify-content-between flex-wrap">
            <span>
                <NavComponent signOut={undefined} user={undefined} props={undefined} />
                <Button style={{ marginLeft: "5px"}} label="Transactions" className="p-button-text p-button-plain" disabled={false} />
            </span>

            <span className="p-input-icon-left">
                
                <Button label="Export" className="p-button-sm" onClick={() => exportTransactionsAsCSV()} style={{marginRight: "10px"}}/>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </span>
        </span>

    );

    return (
        <div className="card" style={{ height: '100vh' }}>
            {transactionsErrorMessage ? <Message severity="error" text={transactionsErrorMessage} /> : undefined}
            <Toast ref={toastTL} position="top-left" />
            <DataTable
                value={transactions}
                ref={(el) => transactionsDT = el}
                header={transactionsHeader}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={500} 
                rowsPerPageOptions={[200,500,1000]}
                dataKey="id"
                rowHover 
                filterDisplay="row"
                filters={transactionsFilters} 
                loading={loading} 
                scrollHeight="flex" scrollable
                responsiveLayout="scroll"
                emptyMessage="No transactions found."
                sortField="authDate" sortOrder={-1}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Column field="id" header="Transaction Id" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '14rem' }} />
                    <Column field="cardNumber" header="Card Number" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '14rem' }} />
                    <Column field="amount" header="Amount" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }}/>
                    <Column field="description" header="Description" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }} />
                    <Column field="bookingId" header="BookingId" filter showFilterMenu={true} showFilterMenuOptions={true} filterMatchModeOptions={matchModes} showClearButton={false} sortable style={{ minWidth: '8rem' }} />
                    <Column field="status" header="Status" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }} />
                    <Column field="creator" header="Creator" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }} />
                    <Column field="authDate" header="Purchase Date" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }} body={(rowData: any) => epochTemplate(Number(rowData.authDate))} />
                    <Column field="modifiedDate" header="Last Modified" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }} body={(rowData: any) => epochTemplate(Number(rowData.modifiedDate))} />
                    <Column field="postedDate" header="Posted Date" filter showFilterMenu={false} showClearButton={false} sortable style={{ minWidth: '8rem' }} body={(rowData: any) => epochTemplate(Number(rowData.postedDate))} />
                    <Column header="Actions" style={{ minWidth: '8rem' }} body={actionBodyTemplate} />
            </DataTable>
        </div>
    )
};

export default withAuthenticator(TransactionsContainer, {hideSignUp: true});