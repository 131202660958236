import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';

const NavComponent = ({ signOut, user, props}) => {
    const [visibleLeft, setVisibleLeft] = useState(false);
    let navigate = useNavigate();

    const handleLogout = () => {
        signOut();
    };

    return (
        <span>
            <Sidebar visible={visibleLeft} onHide={() => setVisibleLeft(false)}>
                <h3>Hello {user.username}</h3>
                <div>
                    <Button icon="pi-arrow-up-right" label="Approvals" className="p-button-link" onClick={() => navigate("/approvals", { replace: true })}/>
                </div>
                <div>
                    <Button icon="pi-arrow-up-right" label="Bookings" className="p-button-link" onClick={() => navigate("/bookings", { replace: true })}/>
                </div>
                <div>
                    <Button icon="pi-arrow-up-right" label="Payments" className="p-button-link" onClick={() => navigate("/payments", { replace: true })}/>
                </div>
                <div>
                    <Button icon="pi-arrow-up-right" label="Transactions" className="p-button-link" onClick={() => navigate("/transactions", { replace: true })}/>
                </div>
                <div>
                    {user && <Button label="Logout" onClick={handleLogout} />}
                </div>
            </Sidebar>
            <Button icon="pi pi-align-justify" onClick={() => setVisibleLeft(true)} />
        </span>
    )
}

export default withAuthenticator(NavComponent, {hideSignUp: true});