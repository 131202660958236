import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import '@aws-amplify/ui-react/styles.css';
import ApprovalsContainer from "./approvals/ApprovalsContainer";
import BookingsContainer from "./bookings/BookingsContainer";
import PaymentsContainer from "./payments/PaymentsContainer";
import TransactionsContainer from "./transactions/TransactionsContainer";

const Root = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<ApprovalsContainer />} />
                <Route path="/approvals" element={<ApprovalsContainer />} />
                <Route path="/bookings" element={<BookingsContainer />} />
                <Route path="/payments" element={<PaymentsContainer />} />
                <Route path="/transactions" element={<TransactionsContainer />} />
            </Routes>
        </BrowserRouter>
    );
}
export default Root;