import React, { useState } from "react";
import NavComponent  from "../nav/NavComponent";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterService } from 'primereact/api';
import MonthUtil from "../../util/MonthUtil";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from 'primereact/inputnumber';
import APIGContainer from "../../container/auth/APIGContainer";
import TotalsComponent from "./TotalsComponent";
import CommonUtil from "../../util/CommonUtil";

const PaymentsComponent = (props: any) => {

    var dt = null;

    const [isTotalsDialogVisible, setIsTotalsDialogVisible] = useState(false);

    const [globalFilter, setGlobalFilter] = useState('');
    const toastTL = useRef(null);

    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'orderNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'emailId': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'eventName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'venue': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sectionRow': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'price': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'eventTime': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'deliveryMethod': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'source': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'capturedAt': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'ccNumber': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'username': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'soldQuantity': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'profit': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'equity': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'owed': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'paid': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'cancelled': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'notes': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'lastUpdatedTime': { value: null, matchMode: FilterMatchMode.CUSTOM }
    });

    FilterService.register("custom_capturedAt", CommonUtil.dateFilter);
    FilterService.register("custom_lastUpdatedTime", CommonUtil.dateFilter);
    FilterService.register("custom_eventTime", CommonUtil.eventTimeFilter);

    const owedTemplate = (rowData: any) => {
        return rowData.owed && rowData.owed !== "" ? "$" + rowData.owed : "";
    }

    const paidTemplate = (rowData: any) => {
        if(rowData.paid === "true") {
            return <i className="pi pi-check-circle" />
        } else {
            return <i className="pi pi-times-circle" />
        }
    }

    const cancelledTemplate = (rowData: any) => {
        if(rowData.cancelled === "true") {
            return <i className="pi pi-check-circle" />
        } else {
            return <i className="pi pi-times-circle" />
        }
    }

    const equityTemplate = (rowData: any) => {
        return rowData.equity && rowData.equity !== "" ? rowData.equity + "%" : "";
    }

    const columns = [
        { field: "orderNumber", header: "Order#", sortable: false, minHeaderHeight: "100px" },
        { field: "emailId", header: "Email", sortable: false, minHeaderHeight: "75px" },
        { field: "eventName", header: "Event", sortable: true, minHeaderHeight: "150px" },
        { field: "venue", header: "Venue", sortable: true, minHeaderHeight: "150px" },
        { field: "sectionRow", header: "Seat", sortable: false, minHeaderHeight: "120px" },
        { field: "price", header: "Price", sortable: true, minHeaderHeight: "75px" },
        { field: "eventTime", header: "Event Time", sortable: true, minHeaderHeight: "75px" },
        { field: "deliveryMethod", header: "Delivery", sortable: false, minHeaderHeight: "100px" },
        { field: "source", header: "Source", sortable: false, minHeaderHeight: "30px" },
        { field: "capturedAt", header: "Captured Time", sortable: true, minHeaderHeight: "75px" },
        { field: "ccNumber", header: "CC", minHeaderHeight: "75px" },
        { field: "username", header: "Name", minHeaderHeight: "50px" },
        { field: "soldQuantity", header: "Sold", minHeaderHeight: "75px" },
        { field: "profit", header: "Profit", minHeaderHeight: "75px" },
        { field: "equity", header: "Equity", editor: true, minHeaderHeight: "75px" },
        { field: "owed", header: "Owed", editor: true, minHeaderHeight: "75px" },
        { field: "paid", header: "Paid", minHeaderHeight: "75px" },
        { field: "cancelled", header: "Cancelled", minHeaderHeight: "80px" },
        { field: "notes", header: "Notes", editor: true, minHeaderHeight: "150px" },
        { field: "lastUpdatedTime", header: "Last Updated", minHeaderHeight: "75px" },
        { header: "Actions", minHeaderHeight: "75px" }
    ]
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }
    
    const textEditor = (options, minHeaderHeight) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{minWidth: minHeaderHeight}} />;
    }

    const numberEditor = (options, minHeaderHeight, max, suffix, prefix) => {
        return <InputNumber mode="decimal" value={options.value} onValueChange={(e) => options.editorCallback(e.target.value)} 
        min={0} max={max} suffix={suffix} prefix={prefix} inputStyle={{width: minHeaderHeight}} maxFractionDigits={2} />;
    }

    const checkboxEditor = (options) => {
        return <input type='checkbox' defaultChecked={options.value === "true"} onChange={(event) => options.editorCallback(event.target.checked ? "true" : "false")} />;
    }

    const timeEditor = (options) => {
        return options.value && new Date(parseInt(options.value)).toLocaleString();
    }

    const checkboxRowFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />
    }

    const columnComponents = selectedColumns.map((col, i) => {
        if(col.field === "capturedAt") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => timeEditor(options)} sortable={col.sortable} body={(rowData: any) => CommonUtil.dateTemplate(rowData.capturedAt)} style={{ minWidth: col.minHeaderHeight }}/>;
        } else if(col.field === "lastUpdatedTime") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => timeEditor(options)} sortable={col.sortable} body={(rowData: any) => CommonUtil.dateTemplate(rowData.lastUpdatedTime)} style={{ minWidth: col.minHeaderHeight }}/>;
        } else if(col.field === "source") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{minWidth: col.minHeaderHeight }} />;
        } else if(col.field === "emailId") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} editor={(options) => textEditor(options, col.minHeaderHeight)} bodyStyle={{wordBreak: "break-all"}} style={{minWidth: col.minHeaderHeight}} />;
        } else if(col.header === "Actions") {
            return <Column key={i} columnKey={col.field} rowEditor style={{ minWidth: col.minHeaderHeight }} />;
        } else if(col.field === "paid") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => checkboxEditor(options)} style={{minWidth: col.minHeaderHeight}} body={paidTemplate} filterElement={checkboxRowFilterTemplate}/>
        } else if(col.field === "cancelled") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => checkboxEditor(options)} style={{minWidth: col.minHeaderHeight}} body={cancelledTemplate} filterElement={checkboxRowFilterTemplate}/>
        } else if(col.field === "owed") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => numberEditor(options, col.minHeaderHeight, 20000, "", "$")} style={{minWidth: col.minHeaderHeight}} body={owedTemplate} />
        } else if(col.field === "equity") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => numberEditor(options, col.minHeaderHeight, 100, "%", "")} style={{minWidth: col.minHeaderHeight}} body={equityTemplate} />
        } else if(col.field === "notes") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} editor={(options) => textEditor(options, col.minHeaderHeight)} style={{minWidth: col.minHeaderHeight}} />
        } else if(col.field === "username") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} sortable={col.sortable} header={col.header} editor={(options) => textEditor(options, col.minHeaderHeight)} style={{minWidth: col.minHeaderHeight}} />
        } else if(col.field === "ccNumber") {
            return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} sortable={col.sortable} header={col.header} style={{minWidth: col.minHeaderHeight}} body={CommonUtil.ccTemplate} />
        }
        return <Column key={col.field} filter showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minHeaderHeight}} />;
    });

    const onRowEditComplete = (e) => {
        let { newData, index } = e;
        let currentBookings = [...props.bookings];
        const newdataIndex = currentBookings.findIndex(booking => booking.orderNumber === newData.orderNumber);
        newData.lastUpdatedTime = new Date().valueOf();
        currentBookings[newdataIndex] = newData;
        APIGContainer.updateBooking(newData);
        props.setBookings(currentBookings);
    }

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilter(value);    
    }

    const onCustomSaveState = (state) => {
        state.columnVisibility = selectedColumns;
        state.globalFilter = globalFilter;
        localStorage.setItem('dt-state-payments-page', JSON.stringify(state));
    }

    const onCustomRestoreState = () => {
        const state = JSON.parse(localStorage.getItem('dt-state-payments-page'));
        if(state && state.columnVisibility) {
            setSelectedColumns(state.columnVisibility);
        }
        if(state && state.globalFilter) {
            setGlobalFilter(state.globalFilter);
        }
            
        return state;
    }

    const resetTableState = () => {
        dt.reset();
        setSelectedColumns(columns);
        localStorage.removeItem('dt-state-payments-page');
        props.onRefresh();
    }

    const paginatorRight = <Button type="button" icon="pi pi-stop-circle" className="p-button-text" tooltipOptions={{ position: "left"}} tooltip="Reset Preferences" onClick={() => {resetTableState()}}/>;

    const paginatorLeft = <MultiSelect value={selectedColumns} options={columns} selectedItemsLabel={"Showing {} of " + columns.length + " columns"} optionLabel="header" onChange={onColumnToggle} style={{width:'17em'}} maxSelectedLabels={3} />

    const header = (
        <span className="flex justify-content-between flex-wrap">
            <span>
                <NavComponent signOut={undefined} user={undefined} props={undefined} />
                <Button style={{ marginLeft: "5px"}} label="Payments" className="p-button-text p-button-plain" disabled={false} />
            </span>

            <span>
                <Dropdown value={props.forMonth} options={MonthUtil.constructMonths()} onChange={(e) => props.setMonthCallback(e.value)} optionLabel="name" optionValue="name" placeholder="Select Month" />
            </span>
            <span className="p-input-icon-left">
                <Button label="Totals" onClick={() => {setIsTotalsDialogVisible(!isTotalsDialogVisible);}} style={{marginRight: "5px"}} />
                <Button icon="pi pi-refresh" style={{marginRight: "5px"}} onClick={() => props.onRefresh()} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </span>
        </span>
    );

    return (
        <div className="datatable-templating-demo">
            <Toast ref={toastTL} position="top-left" />
            <TotalsComponent 
                isTotalsDialogVisible={isTotalsDialogVisible}
                setIsTotalsDialogVisible={setIsTotalsDialogVisible}
                paymentsPageForMonth={props.forMonth} />
            <div className="card" style={{ height: '100vh' }}>
                <DataTable
                ref={(el) => dt = el}
                value={props.bookings} 
                header={header} 
                loading={props.loading} 
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                rows={500} 
                rowsPerPageOptions={[200,500,1000]}
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                filterDisplay="row"
                filters={filters}
                scrollHeight="flex" scrollable
                reorderableColumns
                autoLayout={true}
                stateStorage="custom" 
                customSaveState={onCustomSaveState} 
                customRestoreState={onCustomRestoreState}
                emptyMessage="No Bookings found."
                globalFilter={globalFilter}
                dataKey="id"
                size="small"
                responsiveLayout={"scroll"}
                editMode="row" onRowEditComplete={onRowEditComplete}
                >
                    {columnComponents}
                </DataTable>
            </div>
        </div>
    );
};

export default PaymentsComponent;