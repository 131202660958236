import { ServiceWorker } from 'aws-amplify';
import APIGContainer from '../container/auth/APIGContainer';

export default class ServiceWorkerUtil {

    public static VAAPI_PUBLIC_ID = "BN_-ianf227P1zW2s7NC_3QUEMqMXrs8ITUnY8oskuuSMkq5WEW4bqGSiwqwKDkkf92m05lT-okCvcy3YeZLrVs";

    public static register() {
        const serviceWorker = new ServiceWorker();
        serviceWorker.register('./service-worker.js', '/').then((registeredServiceWorker: any) => {
            ServiceWorkerUtil._subscribe(registeredServiceWorker).then((data: any) => {
                if(data.isAlreadySubscribed) {
                    console.log("Already subscribed for Push Notifications. Not persisting subscription again. " + JSON.stringify(data.subscription));
                } else {
                    APIGContainer.saveSubscription("approvals", data.subscription, () => {
                        console.log("Successfully saved subscription details");
                    }, () => {
                        console.error("Failed to save Subscription details");
                    });
                }
            }).catch((err) => {
                console.error("Error while subscribing for Push Notifications. Error Message is " + err);
            });
        });
    }

    private static _subscribe(registeredServiceWorker: ServiceWorkerRegistration) {  
        return new Promise((resolve, reject) => {      
            registeredServiceWorker.pushManager.getSubscription().then(function(pushSubscription) {
                if(pushSubscription) {
                    return resolve({
                        isAlreadySubscribed: true,
                        subscription: pushSubscription
                    });
                } else {
                    var options = {
                        userVisibleOnly: true,
                        applicationServerKey: ServiceWorkerUtil._urlB64ToUint8Array(ServiceWorkerUtil.VAAPI_PUBLIC_ID)
                    };
                    registeredServiceWorker.pushManager.subscribe(options).then((pushSubscription) => {
                        return resolve({
                            isAlreadySubscribed: false,
                            subscription: pushSubscription
                        });
                    }).catch((err) => {
                        return reject(err);
                    })
                }
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    private static _urlB64ToUint8Array(base64String: string) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }   
}