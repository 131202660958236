import React, { useEffect, useState } from "react";
import { Message } from 'primereact/message';
import BookingsComponent from "../../component/bookings/BookingsComponent";
import MonthUtil from "../../util/MonthUtil";
import APIGContainer from "../auth/APIGContainer";
import { withAuthenticator } from "@aws-amplify/ui-react";

const BookingsContainer = () => {

    const [bookings, setBookings] = useState<any>([]);
    const [loading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [forMonth, setForMonth] = useState<string>(MonthUtil.getCurrentForMonth());
    const [refreshKey, setRefreshKey] = useState(0);
    const [tokens, setTokens] = useState({});

    useEffect(() => {
        setIsLoading(true);
        setBookings([]);
        setFetchError(null);
        APIGContainer.getBookings(forMonth, (response: any) => {
            onSuccessfulFetch(response)
        }, (error: string) => {
            onFetchFailure(error);
        });
    }, [forMonth, refreshKey]);

    const onSuccessfulFetch = (response: any) => {
        setIsLoading(false);
        try {
            if (response?.bookings?.length > 0) {
                setBookings(response.bookings);
                setTokens(response.tokens);
                setFetchError(null);
            } else {
                setBookings([]);
                setFetchError(null);
            }
        } catch (error) {
            onFetchFailure("Error while fetching Bookings. Please retry after sometime!");
        }
    };

    const onFetchFailure = (error: string) => {
        setIsLoading(false);
        setFetchError(error);
    };

    const onRefresh = () => {
        setRefreshKey(oldKey => oldKey + 1);
    }

    return (
        <div>
            {fetchError ? <Message severity="error" text={fetchError} /> : null}
            <BookingsComponent
                loading={loading}
                bookings={bookings}
                onRefresh={onRefresh}
                forMonth={forMonth}
                setMonthCallback={setForMonth}
                tokens={tokens}
            />
        </div>
    );
};

export default withAuthenticator(BookingsContainer, {hideSignUp: true});