import { API, Auth } from "aws-amplify";
import { ITransaction } from "../../interface/ITransaction";

const BOOKINGS_API_GATEWAY_ENDPOINT = "https://wwym94xso4.execute-api.us-west-1.amazonaws.com/test";
const APPROVALS_API_GATEWAY_ENDPOINT = "https://34jhpgefkj.execute-api.us-west-1.amazonaws.com/test";

export default class APIGContainer {
    public static async getApprovals(forMonth: string, onSuccessfulFetch: Function, onFetchFailure: Function) {
        const path = '/test/v1/approvals';
        const myInit = {
            response: true,
            queryStringParameters: {
                forMonth: forMonth
            },
            headers: { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        API.get("Approvals", path, myInit)
            .then(response => {
                if(response && response.data) {
                    onSuccessfulFetch(response.data);    
                } else {
                    onFetchFailure("Invalid response from getBookings API");
                }
            })
            .catch(error => {
                console.error("Failed getApproval call. Error msg: " + error);
                onFetchFailure("Something went wrong while getting list of approvals. Please retry after sometime");
            });
    }

    public static async updateApproval(newStatus: string, id: string, onSuccess: Function, onFailure: Function) {
        const path = '/test/v1/approvals/' + id;
        const myInit = {
            body: {
                id: id,
                newStatus: newStatus
            },
            headers: { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        API.put("Approvals", path, myInit)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                console.error("Failed updadteApproval call. Error msg: " + error);
                onFailure("Something went wrong while updating Status. Please retry after sometime");
            });
    }

    public static saveSubscription(topic: string, subscription: any, onSuccess: Function, onFailure: Function) {
        fetch(APPROVALS_API_GATEWAY_ENDPOINT + "/v1/subscription", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "TableName": "subscriptions",
                "Item": {
                    "topic": topic,
                    "subscription": encodeURIComponent(JSON.stringify(subscription)),
                    "creationDate": new Date().valueOf()
                }
            })
        }).then((result) => {
            return result.json();
        }).then((jsonResponse) => {
            onSuccess(jsonResponse);
        }).catch((error) => {
            console.error("Failed saveSubscription call. Error msg: " + error);
            onFailure("Something went wrong while saving Subscription details. Please retry after sometime");
        });
    }

    public static async getBookings(forMonth: string, onSuccessfulFetch: Function, onFetchFailure: Function) {
        const apiName = 'Bookings';
        const path = '/test/v1/bookings';
        const myInit = {
            response: true,
            queryStringParameters: {
                forMonth: forMonth
            },
            headers: { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
    
        API.get(apiName, path, myInit)
            .then(response => {
                if(response && response.data) {
                    onSuccessfulFetch(response.data);    
                } else {
                    onFetchFailure("Invalid response from getBookings API");
                }
            })
            .catch(error => {
                console.error("Failed getBookings call. Error msg: " + error);
                onFetchFailure("Something went wrong while getting list of bookings. Please retry after sometime");
            });
    }

    public static async updateBooking(booking: any, successCallback?: Function) {
        // Hack Alert. Remove it after running it for all months
        booking.equity = APIGContainer.getEquityByUsername(booking.username);
        if(booking.owed === "") {
            booking.owed = 0;
        }

        booking.lastUpdatedTime = new Date().valueOf().toString();

        console.log("Booking is " + JSON.stringify(booking));
        const path = '/test/v1/bookings/' + encodeURIComponent(booking.orderNumber);
        const myInit = {
            body: {
                ...booking
            },
            headers: { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };

        API.put("Bookings", path, myInit)
            .then(response => {
                console.log("Successfully updated the booking");
                if (successCallback) successCallback();
            })
            .catch(error => {
                console.error("Failed updateBooking call. Error msg: " + error);
            });

    }

    public static async getTransactions(callback: Function) {
        const apiName = 'Cards';
        const path = '/test/v1/transactions';
        const myInit = {
            headers: { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
    
        API.get(apiName, path, myInit)
            .then(response => {
                if(response && response.transactions) {
                    callback({response: "Success", transactions: response.transactions});
                } else {
                    throw new Error("Received not ok response");
                }
            })
            .catch(error => {
                console.log(error);
                callback({response: "Failure"});
            });
    }

    public static async updateTransaction(updatedTransaction: ITransaction, successCallback: Function, errorCallback: Function) {
        const apiName = 'Cards';
        const path = '/test/v1/transactions';
        const myInit = {
            body: {
                ...updatedTransaction
            },
            headers: { 
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        };
    
        API.put(apiName, path, myInit)
            .then(response => {
                console.log("Successfully updated the transaction. Response is " + JSON.stringify(response));
                successCallback();
            })
            .catch(error => {
                console.error("Failed updateTransaction call. Error msg: " + error);
                errorCallback();
            });
    }

    /**
     * Updating anything here requires updation in Extension code as well
     */
    public static getEquityByUsername(username) {
        if(username === undefined || username === null || username === "" || username === "-") {
            return "0";
        } else if(username.toLowerCase() === "jon") {
            return "100";
        } else {
            return "50";
        }
    }
}