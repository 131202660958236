import React, { useState } from "react";
import NavComponent  from "../nav/NavComponent";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Dropdown } from "primereact/dropdown";
import MonthUtil from "../../util/MonthUtil";
import CommonUtil from "../../util/CommonUtil";

const ApprovalsComponent = (props: any) => {

    var dt = null;
    FilterService.register("custom_approvalRequestTime", CommonUtil.dateFilter);
    FilterService.register("custom_eventTime", CommonUtil.eventTimeFilter);

    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'eventName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'venue': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'sectionRow': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'price': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'eventTime': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'deliveryMethod': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'source': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'approvalRequestTime': { value: null, matchMode: FilterMatchMode.CUSTOM },
        'status': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'timer': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'quantityPrice': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'username': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [globalFilter, setGlobalFilter] = useState('');
    const toastTL = useRef(null);

    const handleActions = (newStatus: string, id: string) => {
        props.updateApprovalStatusCallback(newStatus, id, () => {
            toastTL.current.show({severity:'success', summary: 'Approval ' + newStatus, life: 2000});
        });
    }

    const actionsTemplate = (rowData: any) => {
        if(rowData.status === "Pending") {
            return (<div>
                <Button icon="pi pi-check" className="p-button-rounded" style={{marginRight: "3px"}} onClick={() => handleActions("Granted", rowData.id)} />
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => handleActions("Rejected", rowData.id)} />
            </div>);
        } else {
            return;
        }
    }

    const columns = [
        { field: "eventName", header: "Event", sortable: true, minColumnWidth: "200px"},
        { field: "venue", header: "Venue", sortable: true, minColumnWidth: "200px" },
        { field: "sectionRow", header: "Seat", sortable: false, minColumnWidth: "150px" },
        { field: "price", header: "Price", sortable: true, minColumnWidth: "100px" },
        { field: "eventTime", header: "Event Time", sortable: true, minColumnWidth: "100px" },
        { field: "deliveryMethod", header: "Delivery", sortable: false, minColumnWidth: "100px" },
        { field: "source", header: "Source", sortable: false, minColumnWidth: "75px" },
        { field: "approvalRequestTime", header: "Approval Time", sortable: true, minColumnWidth: "100px" },
        { field: "status", header: "Status", sortable: true, minColumnWidth: "100px" },
        { field: "timer", header: "Timer", sortable: false, minColumnWidth: "100px" },
        { field: "quantityPrice", header: "Q-P", sortable: false, minColumnWidth: "100px" },
        { field: "username", header: "Name", sortable: false, minColumnWidth: "100px" },
        { header: 'Actions', minColumnWidth: "150px"}
    ]
    const [selectedColumns, setSelectedColumns] = useState(columns);
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }
    const columnComponents = selectedColumns.map((col, i) => {
        if(col.header === "Actions") {
            return <Column key={i} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} body={actionsTemplate} />;
        } else if(col.field === "approvalRequestTime") {
            return <Column filter key={i} showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} body={(rowData: any) => CommonUtil.dateTemplate(rowData.approvalRequestTime)} style={{ minWidth: col.minColumnWidth }} />;
        } else if(col.field === "source") {
            return <Column filter key={col.field} showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ width: '2%',  minWidth: col.minColumnWidth}} />;
            
        }
        return <Column filter key={col.field} showFilterMenu={false} showClearButton={false} columnKey={col.field} field={col.field} header={col.header} sortable={col.sortable} style={{ minWidth: col.minColumnWidth }} />;
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilter(value);
    }

    const onCustomSaveState = (state) => {
        state.columnVisibility = selectedColumns;
        state.globalFilter = globalFilter;
        localStorage.setItem('dt-state-demo-custom', JSON.stringify(state));
    }

    const onCustomRestoreState = () => {
        const state = JSON.parse(localStorage.getItem('dt-state-demo-custom'));
        if(state && state.columnVisibility) {
            setSelectedColumns(state.columnVisibility);
        }
        if(state && state.globalFilter) {
            setGlobalFilter(state.globalFilter);
        }
            
        return state;
    }

    const resetTableState = () => {
        dt.reset();
        setSelectedColumns(columns);
        localStorage.removeItem('dt-state-demo-custom');
        props.onRefresh();
    }

    const paginatorRight = <Button type="button" icon="pi pi-stop-circle" className="p-button-text" tooltipOptions={{ position: "left"}} tooltip="Reset Preferences" onClick={() => {resetTableState()}}/>;

    const paginatorLeft = <MultiSelect value={selectedColumns} options={columns} selectedItemsLabel={"Showing {} of " + columns.length + " columns"} optionLabel="header" onChange={onColumnToggle} style={{width:'17em'}} maxSelectedLabels={3} />

    const header = (
        <span className="flex justify-content-between flex-wrap">

            <span>
                <NavComponent signOut={undefined} user={undefined} props={undefined} />
                <Button style={{ marginLeft: "5px"}} label="Approvals" className="p-button-text p-button-plain" disabled={false} />
            </span>
            
            <span>
                <Dropdown value={props.forMonth} options={MonthUtil.constructMonths()} onChange={(e) => props.setMonthCallback(e.value)} optionLabel="name" optionValue="name" placeholder="Select Month" />
            </span>
            <span className="p-input-icon-left">
                <Button icon="pi pi-refresh" style={{marginRight: "5px"}} onClick={() => props.onRefresh()} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" value={globalFilter} onChange={onGlobalFilterChange} placeholder="Search" />
                </span>
            </span>
        </span>
    );

    return (
        <div className="datatable-templating-demo">
            <Toast ref={toastTL} position="top-left" />
            <div className="card" style={{ height: '100vh' }}>
                <DataTable
                ref={(el) => dt = el}
                value={props.approvals} 
                header={header} 
                loading={props.loading} 
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                rows={500} 
                rowsPerPageOptions={[200,500,1000]}
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                filterDisplay="row"
                filters={filters}
                scrollable scrollHeight="flex"
                reorderableColumns
                autoLayout={true}
                stateStorage="custom" 
                customSaveState={onCustomSaveState} 
                customRestoreState={onCustomRestoreState}
                emptyMessage="No Approvals found."
                globalFilter={globalFilter}
                dataKey="id"
                size="small"
                responsiveLayout={"scroll"}>
                    {columnComponents}
                </DataTable>
            </div>
        </div>
    );
};

export default ApprovalsComponent;