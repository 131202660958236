import CommonUtil from "../../util/CommonUtil";

export default class TotalsContainer {

    public static getMontlyTotals(bookings: any) {
        const usernameGroupedBookings = TotalsContainer.groupBy(bookings, booking => booking.username);
        const usernameGroupedTotalsList = [];

        for (const [username, groupedBookings] of usernameGroupedBookings.entries()) {
            let totalOwed = 0;
            for(var i=0; i<groupedBookings.length; i++) {
                const booking = groupedBookings[i];
                if(booking.paid === "" || booking.paid === "false") {
                    const owedAmount = CommonUtil.isNumber(booking.owed) ? parseFloat(booking.owed) : 0;
                    totalOwed = totalOwed + owedAmount;
                }
            }
            usernameGroupedTotalsList.push({username: username, totalOwed: totalOwed.toFixed(2)});
        }

        return usernameGroupedTotalsList;
    }

    public static groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            let key = keyGetter(item);
            key = key && key.length > 0 ? key.toLowerCase() : "-";
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
}