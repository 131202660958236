import { IListing } from "../../interface/IListing";
import CommonUtil from "../../util/CommonUtil";

const SKYBOX_PURCHASE_ENDPOINT = "https://skybox.vividseats.com/services/purchases";
const SKYBOX_SYNC_INVENTORY_ENDPOINT = "https://skybox.vividseats.com/services/inventory/sync";

let dependenciesToken: any = {};

export default class SkyboxContainer {

    public static listPurchcase(listing: IListing, tokens: any, callback: Function) {
        fetch(SKYBOX_PURCHASE_ENDPOINT, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Application-Token': tokens.skyboxAppToken,
                'X-Api-Token': tokens.skyboxAPItoken,
                'X-Account': tokens.skyboxAccountId
            },
            body: JSON.stringify({
                "lines":[{
                    "amount": listing.price,
                    "quantity": listing.quantity,
                    "description": listing.description,
                    "lineType":"PURCHASE",
                    "lineItemType":"INVENTORY",
                    "inventory":{
                        "cost": listing.price,
                        "unitCost": listing.unitPrice,
                        "seatType": listing.isGA ? "GA" : "CONSECUTIVE",
                        "splitType": listing.isGA ? "ANY" : "DEFAULT",
                        "tickets": this.constructTicketsObj(listing),
                        "zoneSeating":false,
                        "hideSeatNumbers":true,
                        "broadcast":listing.broadcast,
                        "notes": listing.notes,
                        "marketplace":"NONE",
                        "taxRate":null,
                        "headerOpen":true,
                        "listPrice": listing.listingPrice,
                        "inHandDate": listing.inHandDate,
                        "quantity": listing.quantity,
                        "lowSeat": listing.lowSeat,
                        "highSeat": listing.highSeat,
                        "section": listing.section,
                        "row": listing.row,
                        "hasDuplicateTickets":false,
                        "stockType": listing.stockType,
                        "eventId": listing.eventId,
                        "secondRow":null
                    }}],
                    "vendorId": CommonUtil.getVendorId(listing.source),
                    "currencyCode":"USD",
                    "shippingAddressId":5877085,
                    "billingAddressId":5877085,
                    "internalNotes": listing.notes,
                    "externalRef": listing.externalRef
                })
        }).then((result) => {
            if(result.ok) {
                return result.json();
            } else {
                return result.text().then(text => { throw new Error(text) })
            }
        }).then((jsonResponse) => {
            this.syncInventory(jsonResponse, callback);
            // callback({success: jsonResponse});
        }).catch((err) => {
            callback({error: err});
        });
    }

    private static syncInventory(listingResponse: any, callback: Function) {
        console.log("Inventory Id is " + JSON.parse(listingResponse.lines[0].inventory.id))
        fetch(SKYBOX_SYNC_INVENTORY_ENDPOINT, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Application-Token': dependenciesToken.skyboxAppToken,
                'X-Api-Token': dependenciesToken.skyboxAPItoken,
                'X-Account': dependenciesToken.skyboxAccountId
            },
            body: JSON.stringify({
                "inventoryIds":[
                    JSON.parse(listingResponse.lines[0].inventory.id)
                ]})
        }).then((result) => {
            if(result.ok) {
                console.log("Received success response sync call");
                callback({success: listingResponse});
            } else {
                return result.text().then(text => { throw new Error(text) })
            }
        }).catch((err) => {
            callback({error: err});
        });
    }

    private static constructTicketsObj = (listing: IListing) => {
        const tickets = [];
        let lowSeat = parseInt(listing.lowSeat);
        if(!lowSeat) {
            throw new Error("Invalid Low Seat number");
        }
        for(let i=0; i<listing.quantity; i++) {
            const ticket = {
                "eventId": listing.eventId,
                "faceValue":0,
                "stockType": listing.stockType,
                "status":"AVAILABLE",
                "section": listing.section,
                "seatNumber": lowSeat,
                "row": listing.row,
                "cost": listing.unitPrice,
                "notes": listing.notes
            };
            tickets.push(ticket);
            lowSeat++;
        }
        return tickets;
    }
}