export default class CommonUtil {

    public static isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

    public static ccTemplate = (rowData: any) => {
        const data = rowData.ccNumber;
        const numberMatch = data.match(/\d+/);
        if(numberMatch && numberMatch.length > 0) {
            return numberMatch[0];
        }
        return data;
    }

    public static getSection = (booking: any) => {
        const sectionRowSeat = booking.sectionRow;
        if(this.isGeneralAdmission(sectionRowSeat)) {
            return sectionRowSeat;
        }
        if(booking.source === "TM") {
            const splittedSectionRowSeat = sectionRowSeat.split(",");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                const sectionPart = splittedSectionRowSeat[0];
                const splittedSectionPart = sectionPart.split(" ");
                if(splittedSectionPart && splittedSectionPart.length === 2) {
                    return splittedSectionPart[1];
                } else if(splittedSectionPart && splittedSectionPart.length > 2) {
                    return splittedSectionPart.slice(1, splittedSectionPart.length).join(" ");
                }
            }
        } else if(booking.source === "AXS" || booking.source === "TT") {
            const splittedSectionRowSeat = sectionRowSeat.split(",");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                return splittedSectionRowSeat[0];
            }
        }
        return sectionRowSeat;
    }

    public static getRow = (booking: any) => {
        const sectionRowSeat = booking.sectionRow;
        if(this.isGeneralAdmission(sectionRowSeat)) {
            return sectionRowSeat;
        }

        if(booking.source === "TM") {
            const splittedSectionRowSeat = sectionRowSeat.split(", ");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                const rowPart = splittedSectionRowSeat[1];
                const splittedRowPart = rowPart.split(" ");
                if(splittedRowPart && splittedRowPart.length === 2) {
                    return splittedRowPart[1];
                }
            }
        } else if(booking.source === "AXS" || booking.source === "TT") {
            const splittedSectionRowSeat = sectionRowSeat.split(",");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                return splittedSectionRowSeat[1];
            }
        }
        return sectionRowSeat;
    }

    public static getHighSeatNumber = (booking: any, quantity: number, lowSeat: string) => {
        const sectionRowSeat = booking.sectionRow;
        if(this.isGeneralAdmission(sectionRowSeat)) {
            const highSeatNumber = parseInt(lowSeat) + quantity - 1;
            return "" + highSeatNumber;
        }

        if(booking.source === "TM") {
            const splittedSectionRowSeat = sectionRowSeat.split(", ");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                const seatPart = splittedSectionRowSeat[2];
                const matchingSeatPart = seatPart.replace(/[^0-9-]/g, "");
                if(matchingSeatPart) {
                    const splittedMatchingseats = matchingSeatPart.split("-");
                    if(splittedMatchingseats && splittedMatchingseats.length === 2) {
                        return splittedMatchingseats[1];
                    }
                }
            }
        } else if(booking.source === "AXS" || booking.source === "TT") {
            const splittedSectionRowSeat = sectionRowSeat.split(",");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                const seatPart = splittedSectionRowSeat[2];
                const splittedSeatPart = seatPart.split("-");
                if(splittedSeatPart && splittedSeatPart.length > 0) {
                    return splittedSeatPart[splittedSeatPart.length - 1];
                } else {
                    return seatPart;
                }
            }
        }
        return "";
    }

    public static getLowSeatNumber = (booking: any) => {
        const sectionRowSeat = booking.sectionRow;
        if(this.isGeneralAdmission(sectionRowSeat)) {
            return "" + Math.floor(Math.random() * 10000);
        }

        if(booking.source === "TM") {
            const splittedSectionRowSeat = sectionRowSeat.split(", ");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                const seatPart = splittedSectionRowSeat[2];
                const matchingSeatPart = seatPart.replace(/[^0-9-]/g, "");
                if(matchingSeatPart) {
                    const splittedMatchingseats = matchingSeatPart.split("-");
                    if(splittedMatchingseats && splittedMatchingseats.length === 2) {
                        return splittedMatchingseats[0];
                    }
                }
            }
        } else if(booking.source === "AXS" || booking.source === "TT") {
            const splittedSectionRowSeat = sectionRowSeat.split(",");
            if(splittedSectionRowSeat && splittedSectionRowSeat.length === 3) {
                const seatPart = splittedSectionRowSeat[2];
                const splittedSeatPart = seatPart.split("-");
                if(splittedSeatPart && splittedSeatPart.length > 0) {
                    return splittedSeatPart[0];
                } else {
                    return seatPart;
                }
            }
        }
        return "";
    }

    public static isGeneralAdmission = (data: string) => {
        const rawData = data.toLowerCase().trim();
        if(rawData.includes("general admission") || rawData.includes("ga") || rawData.includes("floor") 
            || rawData.includes("ticketing request") || rawData.includes("lawn") || rawData.includes("genadm")) {
            return true;
        }
        return false;
    }

    public static getVendorId(bookingSource: string) {
        if(bookingSource === "TM") {
            return "299091";
        } else if(bookingSource === "AXS") {
            return "1567434";
        } else if(bookingSource === "TT") {
            return "1858587";
        } else {
            throw Error("Unsupported booking source");
        }
    }

    public static getStockType(bookingSource: string) {
        if(bookingSource === "TM") {
            return "MOBILE_TRANSFER";
        } else if(bookingSource === "AXS") {
            return "FLASH";
        } else if(bookingSource === "TT") {
            return "MOBILE_TRANSFER";
        } else {
            throw Error("Unsupported booking source");
        }
    }

    public static getPrice(price: string) {
        if(price && price.startsWith("$")) {
            price = price.slice(1, price.length);
        }
        if(price && price.includes(".")) {
            price = parseFloat(price.replace(/,/g, '')).toString();
        }
        
        return parseFloat(price);
    }

    public static getStartAndEndEventTime = (booking: any) => {
        let eventTime = booking.eventTime;
        if(eventTime.includes("•")) {
            eventTime = eventTime.split(" •").join("");
        } else if(eventTime.includes(" –")) {
            eventTime = eventTime.replace(" –", "");
        } else if(eventTime.includes(" -")) {
            eventTime = eventTime.replace(" -", "");
        }
        const parsedEventTime = new Date(eventTime);
        const tomorrowEventTime = new Date(parsedEventTime.getTime());
        tomorrowEventTime.setDate(tomorrowEventTime.getDate() + 1);
        return {
            startTime: [parsedEventTime.getFullYear(), ("0" + (parsedEventTime.getMonth()+1)).slice(-2), ("0" + parsedEventTime.getDate()).slice(-2)].join("-"),
            endTime: [tomorrowEventTime.getFullYear(), ("0" + (tomorrowEventTime.getMonth()+1)).slice(-2), ("0" + tomorrowEventTime.getDate()).slice(-2)].join("-")
        };
    }

    public static getQuantity = (booking: any) => {
        const data = booking.quantity;
        if(data && data.split(" ").length > 0) {
            const splittedData = data.split(" ");
            const quantity = splittedData[splittedData.length - 1];
            return quantity.replace(/[^0-9]/g,'');
        }
        return data;
    }

    public static getUnitPrice(price: number, quantity: number) {
        const priceNum = price;
        const quantityNum = quantity;
        const unitPrice = (priceNum/quantityNum).toFixed(2);
        return parseFloat(unitPrice);
    }

    public static dateTemplate = (data: any) => {
        return data && new Date(parseInt(data)).toLocaleString();
    }

    public static dateFilter = (value: any, filter: any) => {
        if(!filter) return true;
        return new Date(parseInt(value)).toLocaleString().includes(filter);
    };

    public static eventTimeFilter = (value: any, filter: any) => {
        if(!filter) return true;
        var splittedFilterList = [];
        const spaceCount = filter.split(" ").length - 1;
        if(spaceCount === 2) {
            var index = filter.lastIndexOf(' ');
            splittedFilterList = [filter.slice(0, index), filter.slice(index + 1)]
        } else if(spaceCount === 1) {
            const lastWord = filter.split(" ")[1];
            if(lastWord.length > 2) splittedFilterList = filter.split(" ");
            else splittedFilterList = [filter];
        } else {
            splittedFilterList = [filter]
        }
        return splittedFilterList.every(splittedFilter => new Date(value.replaceAll("-", "").replaceAll("•", "")).toString().toLowerCase().includes(splittedFilter.toLowerCase()));
    };
}