import React, { useEffect, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ToggleButton } from 'primereact/togglebutton';
import { Dialog } from "primereact/dialog";
import SkyboxContainer from "../../container/skybox/SkyboxContainer";
import { IListing } from "../../interface/IListing";
import CommonUtil from "../../util/CommonUtil";
import { InputNumber } from "primereact/inputnumber";


const ListingComponent = (props: any) => {

    const [listingDialog, setListingDialog] = useState(false);
    const [listing, setListing] = useState<IListing>(null);
    const [listingErrMsg, setListingErrMsg] = useState(null);

    useEffect(() => {
        constructListing(props.booking);
    }, []);

    const constructListing = (booking: any) => {
        const quantity = parseInt(CommonUtil.getQuantity(booking));
        const section = CommonUtil.getSection(booking).trim();
        const row = CommonUtil.getRow(booking).trim();
        const lowSeat = CommonUtil.getLowSeatNumber(booking).trim();
        const highSeat = CommonUtil.getHighSeatNumber(booking, quantity, lowSeat).trim();
        const price = CommonUtil.getPrice(booking.price);
        const _listing: IListing = {
            description: booking.eventName + "\\n" + booking.venue + "\\n" + booking.eventTime + "\\nSection: " + section + ", Row: " + row + ", Seat: " + lowSeat + "-" + highSeat,
            quantity: quantity,
            price: price,
            unitPrice: CommonUtil.getUnitPrice(price, quantity),
            listingPrice: 0,
            section: section,
            row: row,
            lowSeat: lowSeat,
            highSeat: highSeat,
            eventId: booking.eventId,
            notes: booking.emailId + " " + booking.orderNumber,
            source: booking.source,
            inHandDate: CommonUtil.getStartAndEndEventTime(booking).startTime,
            broadcast: false,
            eventName: booking.eventName,
            venue: booking.venue,
            eventTime: booking.eventTime,
            externalRef: booking.orderNumber + " / " + booking.emailId,
            isGA: CommonUtil.isGeneralAdmission(booking.sectionRow),
            stockType: CommonUtil.getStockType(booking.source)
        }
        setListing(_listing);
        setListingDialog(true);
    };

    const validateListing = () => {
        let isValid = true;
        if(!listing.description) {
            setListingErrMsg("Invalid Description");
            isValid = false;
        } else if(!listing.quantity) {
            setListingErrMsg("Invalid Quantity");
            isValid = false;
        } else if(!listing.price || listing.price === 0) {
            setListingErrMsg("Invalid price");
            isValid = false;
        } else if(!listing.unitPrice || listing.unitPrice === 0) {
            setListingErrMsg("Invalid unit price");
            isValid = false;
        } else if(!listing.listingPrice || listing.listingPrice === 0) {
            setListingErrMsg("Invalid listing price");
            isValid = false;
        } else if(!listing.row) {
            setListingErrMsg("Invalid row");
            isValid = false;
        } else if(!listing.lowSeat) {
            setListingErrMsg("Invalid lowSeat");
            isValid = false;
        } else if(!listing.highSeat) {
            setListingErrMsg("Invalid highSeat");
            isValid = false;
        } else if(!listing.eventId) {
            setListingErrMsg("Invalid eventId");
            isValid = false;
        } else if(!listing.notes) {
            setListingErrMsg("Invalid notes");
            isValid = false;
        } else if(!listing.inHandDate) {
            setListingErrMsg("Invalid inHandDate");
        } else if(!listing.externalRef || listing.externalRef.split("/").length < 2) {
            setListingErrMsg("Invalid externalRef")
        } else {
            setListingErrMsg(null);
            isValid = true;
        }

        return isValid;
    }

    const hideDialog = () => {
        props.setListBooking(null);
        setListingDialog(false);
    }

    const listBooking = () => {
        if(!validateListing()) {
            return;
        }
        SkyboxContainer.listPurchcase(listing, props.tokens, (response) => {
            if(response && response.error) {
                props.toastTL.current.show({severity:'error', summary: 'Failure', detail: response.error.message ? response.error.message : "A Failure occured while listing booking"});
            } else {
                props.toastTL.current.show({severity:'success', summary: 'Success', detail:'Successfully listed the booking', life: 3000});
                hideDialog();
            }
        });
    }

    const constructListingDescription = (_listing: IListing) => {
        return _listing.eventName + "\\n" + _listing.venue + "\\n" + _listing.eventTime + "\\nSection: " + _listing.section + ", Row: " + _listing.row + ", Seat: " + _listing.lowSeat + "-" + _listing.highSeat;
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || e.value;
        let _listing = {...listing};
        _listing[`${name}`] = val;
        _listing["description"] = constructListingDescription(_listing);

        setListing(_listing);
    }

    const productDialogFooter = (
        <React.Fragment>
            {listingErrMsg ? <Button label={listingErrMsg} className="p-button-danger p-button-text" /> : null}
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={listBooking} />
        </React.Fragment>
    );


    return (
        <Dialog visible={listingDialog} style={{ width: '550px' }} header="List booking on Skybox" modal={false} className="p-fluid" footer={productDialogFooter} onHide={hideDialog} position={"top-right"}>
            <div className="field">
                <label htmlFor="description">Booking</label>
                <InputTextarea id="description" value={listing?.description} onChange={(e) => onInputChange(e, 'description')} className="p-inputtext-sm" autoResize disabled/>
            </div>

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="inHandDate">In Hand Date</label>
                    <InputText id="inHandDate" value={listing?.inHandDate} onChange={(e) => onInputChange(e, 'inHandDate')} />
                </div>
                <div className="field col">
                    <label htmlFor="quantity">Quantity</label>
                    <InputText id="quantity" value={listing?.quantity} onChange={(e) => onInputChange(e, 'quantity')} />
                </div>
                <div className="field col">
                    <label htmlFor="section">Section</label>
                    <InputText id="section" value={listing?.section} onChange={(e) => onInputChange(e, 'section')} />
                </div>
                <div className="field col">
                    <label htmlFor="row">Row</label>
                    <InputText id="row" value={listing?.row} onChange={(e) => onInputChange(e, 'row')} />
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="section">Low Seat</label>
                    <InputText id="lowSeat" value={listing?.lowSeat} onChange={(e) => onInputChange(e, 'lowSeat')} />
                </div>
                <div className="field col">
                    <label htmlFor="highSeat">High Seat</label>
                    <InputText id="highSeat" value={listing?.highSeat} onChange={(e) => onInputChange(e, 'highSeat')} />
                </div>
                <div className="field col">
                    <label htmlFor="price">Buying Price</label>
                    <InputNumber id="price" value={listing?.price} onChange={(e) => onInputChange(e, 'price')} mode="decimal" maxFractionDigits={5} />
                </div>
                <div className="field col">
                    <label htmlFor="unitPrice">Unit Price</label>
                    <InputNumber id="unitPrice" value={listing?.unitPrice} onChange={(e) => onInputChange(e, 'unitPrice')} mode="decimal" maxFractionDigits={5} />
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="notes">Notes</label>
                    <InputText id="notes" value={listing?.notes} onChange={(e) => onInputChange(e, 'notes')} />
                </div>
            </div>

            <div className="formgrid grid">
                <div className="field col">
                    <label htmlFor="eventId">EventId</label>
                    <InputText id="eventId" value={listing?.eventId} onChange={(e) => onInputChange(e, 'eventId')} />
                </div>
                <div className="field col">
                    <label htmlFor="listingPrice">Listing Price</label>
                    <InputNumber id="listingPrice" value={listing?.listingPrice} onChange={(e) => onInputChange(e, 'listingPrice')} mode="decimal" maxFractionDigits={5} />
                </div>
                <div className="field col">
                    <label htmlFor="broadcast">Broadcast</label>
                    <ToggleButton checked={listing?.broadcast} onChange={(e) => onInputChange(e, 'broadcast')} onIcon="pi pi-check" offIcon="pi pi-times" className="w-full sm:w-10rem" aria-label="Confirmation" />
                </div>
            </div>
        </Dialog> 
    )
}

export default ListingComponent;