const APIConfig = {
    API: {
        endpoints: [
            {
                name: "Bookings",
                endpoint: "https://wwym94xso4.execute-api.us-west-1.amazonaws.com"
            },
            {
                name: "Approvals",
                endpoint: "https://34jhpgefkj.execute-api.us-west-1.amazonaws.com"
            },
            {
                name: "Cards",
                endpoint: "https://ifzk9p7746.execute-api.us-west-1.amazonaws.com"
            }
        ]
    }
};

export default APIConfig;