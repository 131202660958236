import React, { useEffect, useRef, useState } from "react";
import { Message } from 'primereact/message';
import ApprovalsComponent from "../../component/approvals/ApprovalsComponent";
import APIGContainer from "../auth/APIGContainer";
import MonthUtil from "../../util/MonthUtil";
import { withAuthenticator } from "@aws-amplify/ui-react";

const ApprovalsContainer = () => {

    const [approvals, setApprovals] = useState<any>([]);
    const [loading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [forMonth, setForMonth] = useState<string>(MonthUtil.getCurrentForMonth());
    const [refreshKey, setRefreshKey] = useState(0);
    const clientCommunicationChannelRef = useRef(new BroadcastChannel("clientComm"));
    const clientCommunicationChannel = clientCommunicationChannelRef.current;

    useEffect(() => {
        setIsLoading(true);
        setApprovals([]);
        setFetchError(null);
        APIGContainer.getApprovals(forMonth, (response: any) => {
            onSuccessfulFetch(response)
        }, (error: string) => {
            onFetchFailure(error);
        });
    }, [forMonth, refreshKey]);

    useEffect(() => {
        clientCommunicationChannel.onmessage = function (e) {
            if(e.data.action === "refreshApprovalsPage") {
                setTimeout(() => {
                    onRefresh();
                }, 2000);
            }
        };
      }, [clientCommunicationChannel]);

    const onSuccessfulFetch = (response: any) => {
        setIsLoading(false);
        setApprovals(response);
        setFetchError(null);
    };

    const onFetchFailure = (error: string) => {
        setIsLoading(false);
        setFetchError(error);
    };

    const updateApprovalStatusCallback = (newStatus: string, id: string, successCallback: Function) => {
        APIGContainer.updateApproval(newStatus, id, (response: any) => {
            const result = approvals.map((x: any) => [response].find(({ id }) => id === x.id) || x);
            setApprovals(result);
            successCallback();
        }, (error: string) => {
            setFetchError(error);
        });
    }

    const onRefresh = () => {
        setRefreshKey(oldKey => oldKey + 1);
    }

    return (
        <div>
            {fetchError ? <Message severity="error" text={fetchError} /> : null}
            <ApprovalsComponent
                loading={loading}
                approvals={approvals}
                updateApprovalStatusCallback={updateApprovalStatusCallback}
                onRefresh={onRefresh}
                forMonth={forMonth}
                setMonthCallback={setForMonth}
            />
        </div>
    );
};

export default withAuthenticator(ApprovalsContainer, {hideSignUp: true});