const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

export default class MonthUtil {

    public static getCurrentForMonth = () => {
        const date = new Date();
        const month = MONTH_NAMES[date.getUTCMonth()];
        const year = date.getUTCFullYear();
    
        return month + "-" + year;
    }

    public static getYearFromForMonth = (forMonth: string): number => {
        const year = forMonth.split("-")[1];
        return parseInt(year);
    }

    public static getMonthFromForMonth = (forMonth: string): number => {
        const monthAbbr = forMonth.split("-")[0];
        return MONTH_NAMES.indexOf(monthAbbr);
    }

    public static getStartEpoch = (forMonth: string) => {
        const year = MonthUtil.getYearFromForMonth(forMonth);
        const month = MonthUtil.getMonthFromForMonth(forMonth);
        // Subtracting 1 day milliseconds to ensure starting day of the month is correctly captured.
        return "" + (new Date(year, month, 1).valueOf() - 86400000);
    }

    public static getEndEpoch = (forMonth: string) => {
        const year = MonthUtil.getYearFromForMonth(forMonth);
        const month = MonthUtil.getMonthFromForMonth(forMonth);
        // Adding 1 day milliseconds to ensure ending day of the month is correctly captured.
        return "" + (new Date(year, month + 1, 1).valueOf() + 86400000);
    }

    public static constructMonths = () => {
    
        const monthList = [];
        const currentForMonth = MonthUtil.getCurrentForMonth();
        const startMonthIndex = 2;
        const startYear = 2022;
        let currentMonthIndex = MonthUtil.getMonthFromForMonth(currentForMonth);
        let currentYear = MonthUtil.getYearFromForMonth(currentForMonth);

        monthList.push({
            name: "All"
        });
    
        while(currentMonthIndex !== startMonthIndex || currentYear !== startYear) {
            monthList.push({
                name: MONTH_NAMES[currentMonthIndex] + "-" + currentYear
            })
            if(currentMonthIndex === 0) {
                monthList.push({
                    name: currentYear
                });
                currentMonthIndex = 11;
                currentYear--;
            } else {
                currentMonthIndex--;
            }
        }

        monthList.push({
            name: startYear
        })
    
        return monthList;
    }
}